import {range} from "../helpers/range";

export default function Review({ picture, name, role, stars = 0, text }){

	return (
		<div className="otziv">
			<div className="ava"><img src={ picture } alt="" /></div>
			<div className="alltext">
				<div className="top">
					<div className="left">
						<div className="name">{ name }</div>
						<div className="dolg">{ role }</div>
					</div>
					<div className="stars">
						{ range(stars).map(i => (<div key={i} className="star" />)) }
					</div>
				</div>
				<div className="bot">{ text }</div>
			</div>
		</div>
	)

}