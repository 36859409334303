import {range} from "../helpers/range";

export default function Psiholog({ picture, name, stars = 0 }){

	return (
		<div className="psiholog">
			<div className="ava"><img src={ picture } alt="" /></div>
			<div className="name">{ name }</div>
			<div className="bot">
				<div className="stars">
					{ range(stars).map(i => (<div key={i} className="star" />)) }
				</div>
				<a className="arrow" />
			</div>
		</div>
	)

}