import Review from "./Review";
import {useEffect, useRef} from "react";

export default function Reviews({ items }){

	let el = useRef();

	useEffect(() => {

		window.$(el.current).slick({
			dots : true,
			infinite : true,
			speed : 800,
			slidesToScroll : 1,
			arrows : false,
			adaptiveHeight: true,
			// centerMode: true,
			// centerPadding: '0px',
			slidesToShow: 2,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						// centerMode: true,
						centerPadding: '40px',
						slidesToShow: 1
					}
				}
			]
		});

	}, []);

	return (
		<div className="otzivs" ref={ el }>
			{ items.map((item, i) => ( <div><Review key={i} { ...item } /></div> )) }
		</div>
	)

}