import {useEffect, useRef} from "react";

export default function Numbers(){

	let slider = useRef();

	useEffect(() => {

		window.$(slider.current).slick({
			dots : true,
			infinite : true,
			speed : 500,
			dotsClass : 'my-dots',
			adaptiveHeight : true,
			slidesToShow : 1,
			slidesToScroll : 1,
			arrows : false,
		});

	}, []);

	return (
		<div className="numbers" ref={ slider }>
			<div className="number">
				<div className="phone">
					<img src="images/phone.png" alt="" />
				</div>
				<div className="zag">Заполните простую анкету</div>
				<div className="text">Расскажите, что вас беспокоит и что важно в психологе.</div>
			</div>
			<div className="number">
				<div className="phone">
					<img src="images/phone.png" alt="" />
				</div>
				<div className="zag">Выберите психолога</div>
				<div className="text">Выберите понравившегося психолога</div>
			</div>
			<div className="number">
				<div className="phone">
					<img src="images/phone.png" alt="" />
				</div>
				<div className="zag">Выберите способ связи с психологом</div>
				<div className="text">49руб/мин по аудио/видео связи200руб за 24 часа чата + аудио сообщения</div>
			</div>
			<div className="number">
				<div className="phone">
					<img src="images/phone.png" alt="" />
				</div>
				<div className="zag">Начните общение</div>
				<div className="text">Все общение происходит через платформу SPEAK. Вслучае если психолог вам не
					понравится, вы можете заменить его без дополнительных переплат
				</div>
			</div>
		</div>
	);

}