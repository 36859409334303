import Psiholog from "./Psiholog";
import {useEffect, useRef} from "react";

export default function Psihologs({ items = [] }){

	let el = useRef();

	useEffect(() => {

		let $el = window.jQuery(el.current);

		$el.slick({
			dots : true,
			infinite : true,
			speed : 800,
			slidesToShow : 5,
			slidesToScroll : 5,
			arrows : false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll : 1
					}
				}
			]
		});

	}, []);

	return (
		<div className="psihologs" ref={el}>
			{ items.map((item, i) => (<div><Psiholog key={ i } {...item} /></div>)) }
		</div>
	)

}