export default function Header(){

	return (
		<header className="head">
			<div className="logo" />
			<div className="menu">
				<a href="#" className="thing-of-menu">About us</a>
				<a href="#" className="thing-of-menu">Blog</a>
				<a href="#" className="thing-of-menu">Contact</a>
			</div>
			<div className="language">
				<div className="lang" />
				<div className="langtext">EN</div>
				<div className="burger" />
			</div>
			<a href="#" className="button">Войти</a>
		</header>
	);

}