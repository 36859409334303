export function range(count){

	let result = [];

	for(let i = 0; i < count; i++){
		result.push(i);
	}

	return result;

}