export default function Footer(){

	return (
		<div className="footer">
			<div className="center">
				<div className="left">
					<div className="logo"><img src="images/logo.svg" alt="" /></div>
					<div className="menu">
						<a className="menucomp">Home</a>
						<a className="menucomp">Blog</a>
						<a className="menucomp">Privacy Policy</a>
						<a className="menucomp">About us</a>
						<a className="menucomp">Contact</a>
						<a className="menucomp">Terms and Conditions</a>
					</div>
				</div>
				<div className="right">
					<div className="social">
						<a className="soc"><img src="images/facebook.svg" alt="" /></a>
						<a className="soc"><img src="images/inst.svg" alt="" /></a>
						<a className="soc"><img src="images/in.svg" alt="" /></a>
						<a className="soc"><img src="images/twitter.svg" alt="" /></a>
					</div>
					<div className="pochta">info@speak.help</div>
					<div className="adress">33 W 60th Street, 8th FI New York, NY 10023</div>
				</div>
			</div>
		</div>
	);

}