import Header from "./components/Header";
import Youtube from "./components/Youtube";
import Psihologs from "./components/Psihologs";
import Reviews from "./components/Reviews";
import Footer from "./components/Footer";
import Numbers from "./components/Numbers";

function App() {

  let psihologs = [
    {
      'name': 'Илья',
      'picture': 'images/psi1.png',
      'stars': 5
    },
    {
      'name' : 'Илья',
      'picture' : 'images/psi1.png',
      'stars' : 5
    },
    {
      'name' : 'Илья',
      'picture' : 'images/psi1.png',
      'stars' : 5
    },
    {
      'name' : 'Илья',
      'picture' : 'images/psi1.png',
      'stars' : 5
    },
    {
      'name' : 'Илья',
      'picture' : 'images/psi1.png',
      'stars' : 5
    },
    {
      'name' : 'Илья',
      'picture' : 'images/psi1.png',
      'stars' : 5
    },
    {
      'name' : 'Илья',
      'picture' : 'images/psi1.png',
      'stars' : 5
    }
  ];

  let reviews = [
    {
      name : 'Sophia Jones',
      picture : 'images/psi1.png',
      role : 'Teacher',
      stars : 5,
      text : 'After just 2 months, Speak has already made such an impact on my life. It has helped me develop manageable solutions to feelings of stress, anxiety, and overwhelm. Speaker helped me navigate the difficult stages of grief after losing my mom, with compassion and encouragement. And she’s easy to open up to, making me feel heard and understood.'
    },
    {
      name : 'Sophia Jones',
      picture : 'images/psi1.png',
      role : 'Teacher',
      stars : 5,
      text : 'After just 2 months, Speak has already made such an impact on my life. It has helped me develop manageable solutions to feelings of stress, anxiety, and overwhelm. Speaker helped me navigate the difficult stages of grief after losing my mom, with compassion and encouragement. And she’s easy to open up to, making me feel heard and understood.'
    },
    {
      name : 'Sophia Jones',
      picture : 'images/psi1.png',
      role : 'Teacher',
      stars : 5,
      text : 'After just 2 months, Speak has already made such an impact on my life. It has helped me develop manageable solutions to feelings of stress, anxiety, and overwhelm. Speaker helped me navigate the difficult stages of grief after losing my mom, with compassion and encouragement. And she’s easy to open up to, making me feel heard and understood.'
    },
    {
      name : 'Sophia Jones',
      picture : 'images/psi1.png',
      role : 'Teacher',
      stars : 5,
      text : 'After just 2 months, Speak has already made such an impact on my life. It has helped me develop manageable solutions to feelings of stress, anxiety, and overwhelm. Speaker helped me navigate the difficult stages of grief after losing my mom, with compassion and encouragement. And she’s easy to open up to, making me feel heard and understood.'
    },
    {
      name : 'Sophia Jones',
      picture : 'images/psi1.png',
      role : 'Teacher',
      stars : 5,
      text : 'After just 2 months, Speak has already made such an impact on my life. It has helped me develop manageable solutions to feelings of stress, anxiety, and overwhelm. Speaker helped me navigate the difficult stages of grief after losing my mom, with compassion and encouragement. And she’s easy to open up to, making me feel heard and understood.'
    },
    {
      name : 'Sophia Jones',
      picture : 'images/psi1.png',
      role : 'Teacher',
      stars : 5,
      text : 'After just 2 months, Speak has already made such an impact on my life. It has helped me develop manageable solutions to feelings of stress, anxiety, and overwhelm. Speaker helped me navigate the difficult stages of grief after losing my mom, with compassion and encouragement. And she’s easy to open up to, making me feel heard and understood.'
    }
  ];

  return (
    <div className="App">
      <div className="circle" />
      <div className="circlce2" />
      <div className="circlce3" />
      <div className="circlce4" />
      <div className="center">
        <Header />
        <div className="disp1">
          <div className="one">
            <div className="left">
              <div className="uptext">+300 прошедших отбор психологов</div>
              <div className="title">Сессии с психологом <br />Онлайн</div>
              <a className="button">Начать беседу</a>
            </div>
            <div className="right"><img src="images/chel.png" alt="" /></div>
          </div>
          <div className="two">
            <div className="left">
              <Youtube src="https://www.youtube.com/embed/OqiLHNzGjPo" />
            </div>
            <div className="right">
              <div className="title">Скачайте бесплатно <br /> приложение SPEAK</div>
              <div className="app">
                <div className="left-logo" />
                <div className="text">
                  <div className="zag">Speak</div>
                  <div className="casual-text">онлайн сессии с психологом<br />доступно для iOS и Android</div>
                </div>
              </div>
              <div className="download">
                <a className="appstore" />
                <a className="playstore" />
              </div>
            </div>
          </div>
        </div>

        <div className="disp2">
          <div className="title">Психологи работающие с актуальными темами</div>
          <div className="tags">
            <div className="tag">Чувства</div>
            <div className="tag">Одиночество</div>
            <div className="tag">Вредные привычки</div>
            <div className="tag">Отношения</div>
            <div className="tag">Работа</div>
            <div className="tag">Смыслы</div>
            <div className="tag">Одиночество</div>
            <div className="tag">Одиночество</div>
            <div className="tag">Отношения с близкими</div>
            <div className="tag active">+ 300 психологов</div>
          </div>
          <Psihologs items={ psihologs } />
        </div>

        <div className="disp3">
          <div className="left">
            <div className="title">Поможем вам</div>
            <div className="punkti">
              <div className="punkt">Разрешить причины сложных отношений с партнером</div>
              <div className="punkt">Снять или снизить остроту переживаний</div>
              <div className="punkt">Справиться с выгоранием на работе и найти мотивацию</div>
              <div className="punkt">Понять себя в отношениях с близкими людьми</div>
              <div className="punkt">Найти себя и понимать свои желания</div>
              <div className="punkt">Исследовать и управлять изменениями в своей жизни</div>
            </div>
          </div>
          <div className="right"><img src="images/disp3.png" alt="" /></div>
        </div>

        <div className="disp4">
          <div className="left"><img src="images/phone.png" alt="" /></div>
          <div className="right">
            <div className="title">Преимущества онлайн сессий с психологом</div>
            <div className="punkt">
              <div className="zag">Занимайтесь где и как удобно</div>
              <div className="text">Дома или в поездке и в любом часовом поясе</div>
            </div>
            <div className="punkt">
              <div className="zag">Меньше забот- больше мотивации</div>
              <div className="text">Не тратьте время на дорогу и управляйте занятиями в два клика</div>
            </div>
            <div className="punkt">
              <div className="zag">Доверяйте себе</div>
              <div className="text">Выбирайте среди проверенных психологов, которые специаллизируются на ваших
                запросах
              </div>
            </div>
          </div>
        </div>

        <div className="disp5">
          <div className="title">Как мы отбираем специалистов?</div>
          <div className="undertext">Отбор проходят меньше 10% специаллистов</div>
          <div className="under">
            <div className="left">
              <div className="punkt">
                <div className="number"><img src="images/1.png" alt="" /></div>
                <div className="alltext">
                  <div className="zag">Документы об образовании</div>
                  <div className="text">Обязательно иметь высшее психологическое образование и сертификаты для ведения
                    психотерапии
                  </div>
                </div>
              </div>
              <div className="punkt">
                <div className="number"><img src="images/2.png" alt="" /></div>
                <div className="alltext">
                  <div className="zag">Опыт частной практики и личной терапии</div>
                  <div className="text">Нужно не менее 3-х лет частной практики, личная терапия и регулярные
                    супервизии
                  </div>
                </div>
              </div>
              <div className="punkt">
                <div className="number"><img src="images/3.png" alt="" /></div>
                <div className="alltext">
                  <div className="zag">Собеседование с нашим экспертом-ментором</div>
                  <div className="text">Отслеживаются коммуникативные способности, уровень эмпатии, манера и стиль
                    беседы, проффесиональные компетенции
                  </div>
                </div>
              </div>
            </div>
            <div className="right"><img src="images/disp5.png" alt="" /></div>
          </div>
        </div>

        <div className="disp6">
          <div className="title">О сервисе</div>
          <div className="top">
            <div className="left">
              <Youtube src="https://www.youtube.com/embed/OqiLHNzGjPo" />
            </div>
            <div className="right">
              <div className="vieb">
                <div className="zag">900 +</div>
                <div className="text">регистраций вчера</div>
              </div>
              <div className="vieb">
                <div className="zag">75 000 +</div>
                <div className="text">проведено сессий</div>
              </div>
              <div className="vieb">
                <div className="zag">354 +</div>
                <div className="text">психологов</div>
              </div>
            </div>
          </div>
          <div className="bot">
            <div className="punkt">
              <div className="img"><img src="images/disp6one.png" alt="" /></div>
              <div className="alltext">
                <div className="zag">Подбор “По позже”</div>
                <div className="text">Подбор с возможностью бронирование сессии на будущее</div>
              </div>
            </div>
            <div className="punkt">
              <div className="img"><img src="images/disp6two.png" alt="" /></div>
              <div className="alltext">
                <div className="zag">Подбор “Сейчас”</div>
                <div className="text">Подбор с возможностью начала сессии “здесь и сейчас”</div>
              </div>
            </div>
          </div>
        </div>

        <div className="disp7">
          <div className="title">Как это устроено</div>
          <div className="soder">
            <div className="left">
              <div className="punkt">
                <div className="img">1</div>
                <div className="alltext">
                  <div className="zag">Заполните простую анкету</div>
                  <div className="text">Расскажите, что вас беспокоит и что важно в психологе.</div>
                </div>
              </div>
              <div className="punkt">
                <div className="img">3</div>
                <div className="alltext">
                  <div className="zag">Выберите способ связи с психологом</div>
                  <div className="text">49руб/мин по аудио/видео связи200руб за 24 часа чата + аудио сообщения</div>
                </div>
              </div>
            </div>
            <div className="cent">
              <div className="phone"><img src="images/phone.png" alt="" /></div>
              <a className="button2">Начать общение</a>
            </div>
            <div className="left">
              <div className="punkt">
                <div className="img">2</div>
                <div className="alltext">
                  <div className="zag">Выберите психолога</div>
                  <div className="text">Выберите понравившегося психолога</div>
                </div>
              </div>
              <div className="punkt">
                <div className="img">4</div>
                <div className="alltext">
                  <div className="zag">Начните общение</div>
                  <div className="text">Все общение происходит через платформу SPEAK. Вслучае если психолог вам не
                    понравится, вы можете заменить его без дополнительных переплат
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="disp7-mobile">
          <div className="title">Как это устроено</div>
          <Numbers />
          <a className="button2">Начать общение</a>
        </div>


        <div className="disp8">
          <div className="title">Услуги</div>
          <div className="cards">
            <div className="card1">
              <div className="zag">24 часа чата</div>
              <div className="cost"><span>4200₽</span>/24 часа</div>
              <div className="punkti">
                <div className="punkt">24 часа доступа к чату с психологом</div>
                <div className="punkt">Аудио сообщения</div>
              </div>
              <a className="button3">Перейти</a>
            </div>
            <div className="card2">
              <div className="popular">Популярное</div>
              <div className="zag">Аудио/Видео сессии</div>
              <div className="cost"><span>49₽</span>/1 минута</div>
              <div className="punkti">
                <div className="punkt">Безлимитный доступа к чату с психологом до начала сессии</div>
                <div className="punkt">Аудио/Видео звонки</div>
                <div className="punkt">Нет лимитов по времени разговора</div>
              </div>
              <div className="calc">
                <a className="button3">Перейти</a>
              </div>
            </div>
          </div>
        </div>

        <div className="disp9">
          <div className="two">
            <div className="left"><img src="images/disp9phone.png" alt="" /></div>
            <div className="right">
              <div className="title">Скачайте бесплатно <br /> приложение SPEAK</div>
              <div className="app">
                <div className="left-logo" />
                <div className="text">
                  <div className="zag">Speak</div>
                  <div className="casual-text">онлайн сессии с психологом<br />доступно для iOS и Android</div>
                </div>
              </div>
              <div className="download">
                <a className="appstore" />
                <a className="playstore" />
              </div>
            </div>
          </div>
        </div>

        <div className="disp10">
          <div className="title">Отзывы</div>
          <Reviews items={ reviews } />
        </div>

        <div className="disp11">
          <div className="title">Детский психолог онлайн</div>
          <div className="content">
            <div className="left">
              <div className="zag">Чем поможет детский психолог онлайн?</div>
              <div className="text">Каждый родитель хочет, чтобы его ребенок рос сильной уравновешенной личностью. Мы
                понимаем, что именно в детстве формируются зачатки характера. Как справиться с возникающими проблемами,
                как выстроить отношения с ребенком, чтобы он доверял вам? На эти и другие вопросы поможет ответить
                детский психолог онлайн.
              </div>
              <div className="zag">Преимущества онлайн-консультации детского психолога</div>
              <div className="text">Главный плюс сеанса в таком формате – экономия времени. Вам не придется тратить
                время на дорогу и долгие сборы. Все, что нужно – компьютер или смартфон, микрофон и блокнот с ручкой,
                чтобы записывать важную информацию. Если вам или ребенку тяжело общаться с незнакомым человеком лично,
                онлайн-связь в этом случае упростит дело.
              </div>
              <div className="zag">Когда нужна помощь детского психолога онлайн?</div>
              <div className="text">Онлайн-консультация детского психолога может быть полезна, если у вас есть вопросы
                по таким темам, как:
              </div>
              <ul>
                <li>Плохая успеваемость;</li>
                <li>Стеснительность;</li>
                <li>Цифровая зависимость;</li>
                <li>Агрессия;</li>
                <li>Потеря близкого в семье;</li>
                <li>Возрастной кризис;</li>
                <li>Подготовка к детскому саду или школе;</li>
                <li>Профориентация.</li>
              </ul>
              <div className="text">Это лишь часть тем, которые можно затронуть. Помните, что, если возникает ситуация,
                в которой вы самостоятельно не можете разобраться, сейчас консультация детского психолога онлайн поможет
                найти ответы.
              </div>
              <div className="zag">Как проходит онлайн-консультация детского психолога?</div>
              <div className="text">Задать вопрос детскому психологу онлайн может как сам ребенок, так и родитель.</div>
            </div>
            <div className="right">
              <div className="text">На первом сеансе обычно присутствует родитель или опекун, а также сам «виновник
                торжества». Следующие сеансы могут быть и без взрослого, если ребенок сам в состоянии связаться через
                интернет.
                <br /><br />Бывает и так, что сначала ребенку присутствовать необязательно. В этом случае работа происходит
                  с родителем, а если есть необходимость, позже присоединяется ребенок.
                  <br /><br />Психолог по детским вопросам онлайн может попросить ребенка нарисовать что-то, далее по этим
                    рисункам он проведет диагностику. Родитель тоже является звеном связи с психикой ребенка. Через него
                    психолог может сделать вывод о том, какая проблема сейчас назрела.
                    <br /><br />Если специалист попросит вас пройти обследование или сдать анализы для более ясной картины,
                      не пугайтесь, это нормально. Таким образом, вы оба сможете исключить какие-либо диагнозы</div>
              <div className="zag">О чем поговорить с детским психологом?</div>
              <div className="text">Родители малышей часто обращаются с вопросами об адаптации в детском саду. Есть
                тревожные мамы, которые сомневаются, стоит ли отдавать малыша в сад или же пока слишком рано. Очень
                многих волнует, что воспитатель будет срываться на ребенка. Это логично, ведь в силу того, что в СМИ
                регулярно появляются жуткие новости о чудовищных ЧП в детсадах, беспокойство обосновано.
                <br /><br />Отдельный вопрос подготовки к саду. В интернете полно информации в таком ключе: «ребенок должен
                  научиться самостоятельно есть, ходить в туалет и засыпать сам перед тем, как идти в сад». Особо
                  мнительные беспрекословно верят, другие понимают: ребенок ничего никому не должен. Его невозможно
                  научить чему-то, пока он сам не захочет делать это. «Дрессировке» малыши не поддаются. Но пугаться не
                  стоит, в любом случае, воспитатели будут помогать малышам в этих вопросах. Главное – настроиться на
                  позитив и верить в хорошее.
                  <br /><br />Дети постарше сталкиваются с проблемами переходного возраста, неуверенностью в себе,
                    безответной любовью и другими. Если вы понимаете, что вам с чадом необходима онлайн-консультация, вы
                    можете выбрать специалиста на нашем сайте.</div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
